@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');
body{
    background-color: #000;
}
::-ms-input-placeholder {
    /* Edge 12-18 */
    font-size: 14px;
}

::placeholder {
    font-size: 14px;}
.app{
    min-height: 100vh;
}
.MuiGrid-container{
    // padding: 0 16px;
}
.displayFlex{
    display: flex;
}
.alignItemsCenter{
    align-items: center;
}
.justifyContentBetween{
    justify-content: space-between;
}

.menu-contect-section{
    min-height: calc(100vh - 82px);
}

.positiomAbsCenter{
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
